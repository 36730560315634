// import React from 'react';
import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'
import Firstpager from './Components/Firstpager';
import SecondPage from './Components/SecondPage';
import Allcourses from './Components/Allcourses';
import MarketingCourses from './Components/MarketingCourses'
import VisionandMission from './Components/VisionandMission';
import Management from './Components/Management';
import Aboutus from './Components/Aboutus';
import Contactus from './Components/Contactus';
import Navbar from './Components/Navbar';
import FooterComponent from './Components/Footer';
import './Components/Style.css/Loaderanimation.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);
  return (
    <>
    <div>
      {isLoading ? (
        <div class="Loader_card">
          <div class="MainCard_loader">
            <p>loading</p>
            <div class="words">
              <span class="word">buttons</span>
              <span class="word">forms</span>
              <span class="word">switches</span>
              <span class="word">cards</span>
              <span class="word">buttons</span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Firstpager />} />
            <Route path="/SecondPage" element={<SecondPage />} />
            <Route path="/Allcourses" element={<Allcourses />} />
            <Route path="/MarketingCourses" element={<MarketingCourses />} />
            <Route path="/VisionandMission" element={<VisionandMission />} />
            <Route path="/Management" element={<Management />} />
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/Contactus" element={<Contactus />} />
          </Routes>
          <FooterComponent />
        </div>
      )}
      </div>
    </>

  );
}

export default App;
