import React from 'react'

function Management() {
    return (
        <div className='ManagementTeam'>
            <h1>Management Team</h1>
            <div style={{
                marginTop:'40px',
            }}>
                <h3 style={{
                color: 'grey'
            }}>Management Team Benefits</h3>
                <p style={{
                color: 'black', fontWeight: 'bolder'
            }}>
                    Our management team at Inphynous Technical Academy brings a wealth of experience and expertise to guide both the institution and our students toward success. With a combined experience of over 50 years in various facets of the IT industry, our leaders are adept at steering the academy toward innovation and excellence.
                </p>
            </div>

            <div style={{
                marginTop:'40px',
            }}>

                <h3 style={{
                color: 'grey'
            }}>Visionary Leadership</h3>
                <p style={{
                color: 'black', fontWeight: 'bolder'
            }}>
                    Our management team possesses a visionary approach, ensuring that Inphynous remains at the forefront of industry trends. This foresight enables us to tailor our curriculum to meet the ever-evolving demands of the IT sector, providing students with cutting-edge knowledge and skills.
                </p>
            </div>

            <div style={{
                marginTop:'40px',
            }}>

                <h3 style={{
                color: 'grey'
            }}>Goal-Oriented Strategies</h3>
                <p style={{
                color: 'black', fontWeight: 'bolder'
            }}>
                    Each member of our management team is committed to the overarching goal of fostering a conducive learning environment. By setting clear objectives and implementing effective strategies, they ensure that our students not only meet but exceed their academic and professional aspirations.
                </p>
            </div>

            <div style={{
                marginTop:'40px',
            }}>

                <h3 style={{
                color: 'grey'
            }}>Trainer Expertise and Passion</h3>
                <p style={{
                color: 'black', fontWeight: 'bolder'
            }}>
                    Our trainers, boasting over 5 years of experience in the IT industry, are dedicated to the realization of our vision and goals. They bring their wealth of practical knowledge into the classroom, creating an enriching and dynamic learning experience for students.

                </p>
            </div>

            <div style={{
                marginTop:'40px',
            }}>
                <h3 style={{
                color: 'grey'
            }}>Eager to Assist and Collaborate</h3>
                <p style={{
                color: 'black', fontWeight: 'bolder'
            }}>
                    The trainers at Inphynous are not merely instructors; they are mentors who actively engage in students' success. Always eager to help, they foster a collaborative learning atmosphere where questions are encouraged, challenges are embraced, and each student's journey is personally invested in.
                    In choosing Inphynous Technical Academy, you're not just enrolling in a course; you're joining a community led by a management team and trainers who are passionate about your growth and success in the ever-evolving landscape of the IT industry
                </p>
            </div>

        </div>
    )
}

export default Management