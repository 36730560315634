import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from './Assets/profile03.webp';
import image2 from './Assets/Tech.jpg';
import Reactimage from './Assets/React.jpg';
import Angularimage from './Assets/Angular.jpg';
import backendimage from './Assets/JavaBackend.jpg';
import certificate from './Assets/Certificate.png'
import globe from './Assets/worldartboard.png'
import headphone from './Assets/Onlinecall.png'
import mean from './Assets/Mean.jpg'
import mern from './Assets/Mern.jpg'
import angulartranning from './Assets/AngularTranning.jpg'
import reacttranning from './Assets/ReactTranning.jpg'
import image from './Assets/manwithlaptop.png'
import cheersimg from './Assets/ItaBanner.jpg'
import lock from './Assets/Lock.png'
// --------------------------Defined Css-----------------------------
import './Style.css/Slide.css';
import './Style.css/Responsive.css';
import './Style.css/Cardslide.css';
// --------------------------Swiper-----------------------------
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper/modules';

const texts = [
  <div>
    <h2>Job Oriented Program </h2>
    <p>React | Angular | AWS | JAVA</p>
  </div>,
  <div>
    <h2>Trading Technologies</h2>
    <p> AI | React | Angular | .NET | JAVA</p>
  </div>,
  <div>
    <h2>Trusted By 1000+ Students </h2>
    <p> Boost Your Skills & Be Industry Ready</p>
  </div>,
  <div>
    <h2>Become financially Stable </h2>
    <p> Learn Share / Forex Market </p>
  </div>
];


function Firstpager() {

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2500);

    return () => clearInterval(intervalId);
  }, []);



  const [rating, setRating] = useState(0);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  return (

    <div className='Firstpage' id='Firstpage'>
      <div className='slideshow' id='slideshow'>
        <div className='slideshow_text'>
          <p className='head'>Brighten your Career</p>
          <div className="text_slideshow">
            {texts.map((text, index) => (
              <h1 key={index} className={`text-slide ${index === currentIndex ? 'active' : ''}`}>
                {text}
              </h1>
            ))}
          </div>
        </div>
        <div className='image'>
          <img src={image} />
        </div>
      </div>
      <div class="welcome_to_ITA">
        <h1>Welcome To Inphynous Technical Academy !, How can we help you...</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum voluptatum est quo officia error nihil
          fugiat quae quibusdam. Laboriosam quia magnam consectetur? Officia, iusto sed.</p>
      </div>
      <div class="frontpage_cards">
        <div class="forntpage_card">
          <img src={certificate} alt="" style={{ width: '150px' }} />
          <h3>Get a Certificate</h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, nobis sunt reprehenderit numquam nostrum
            perferendis nihil odio hic explicabo voluptatibus!
          </p>
        </div>
        <div class="forntpage_card">
          <img src={globe} alt="" style={{ width: '150px' }} />
          <h3>All Over The Globe</h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, nobis sunt reprehenderit numquam nostrum
            perferendis nihil odio hic explicabo voluptatibus!
          </p>
        </div>
        <div class="forntpage_card">
          <img src={headphone} alt="" style={{ width: '150px' }} />
          <h3>LIVE Online/Offline Lectures</h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, nobis sunt reprehenderit numquam nostrum
            perferendis nihil odio hic explicabo voluptatibus!
          </p>
        </div>
      </div>
      <div class="empower_para">
        At Inphynous Technical Academy, we empower individuals with cutting-edge skills in diverse range of programming
        languages and technologies as well as in share market analysis. Unlock your potential with our comprehensive
        courses and elevate your career to new heights.
      </div>
      <div class="key_features">
        <div class="key_features_left">
          <h1>Key features</h1>
          <div class="heading_and_para">
            <h1>Expert-led Courses:</h1>
            <p>Learn from industry professionals with extensive experience.</p>
          </div>
          <div class="heading_and_para">
            <h1>Hands-on Learning:</h1>
            <p>Gain practical skills through real-world projects.</p>
          </div>
          <div class="heading_and_para">
            <h1>Flexible Learning Paths:</h1>
            <p>Choose courses tailored to your career goals.</p>
          </div>
          <div class="heading_and_para">
            <h1>Lifetime Support:</h1>
            <p>Get lifetime support for the technologies as we have wide community of developers</p>
          </div>
        </div>
        <div class="key_features_right">
          <div class="key_features_card">
            <img src={image1} alt="" style={{ width: '200px' }} />
            <div>
              <p style={{
                fontSize: '30px',
                cursor: 'pointer',
                textAlign: 'center'
              }}>
                Diana Gloster
              </p>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  onClick={() => handleStarClick(star)}
                  style={{
                    fontSize: '50px',
                    cursor: 'pointer',
                    color: star <= rating ? 'gold' : 'gray',
                  }}
                >
                  ★
                </span>
              ))}
            </div>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil illum praesentium veritatis, sit
              voluptatum officia esse dolore provident reprehenderit omnis numquam, asperiores doloremque commodi
              facilis velit natus quas corrupti placeat.
            </p>
          </div>
        </div>
      </div>
      <div class="cheers">
        <div className='cheers_image'><img src={cheersimg} /></div>
      </div>
      <div class="job_orientation">
        <h1>Job Orientation</h1>
        <div class="Job_orientation_container">
          <Swiper
            slidesPerView={3}
            centeredSlides={false}
            slidesPerGroupSkip={3}
            grabCursor={true}
            scrollbar={false}
            navigation={false}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              319: {
                slidesPerView: 1,
              },
              479: {
                slidesPerView: 2,

              },
              767: {
                slidesPerView: 3,
              }
            }}
            modules={[Keyboard, Scrollbar, Navigation, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div class="swippercard">
                <img src={Reactimage} alt="" style={{ width: '250px' }} />
                <div className='starreview'>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>Step into full stack react</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/SecondPage">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={Angularimage} alt="" style={{ width: '250px' }} />
                <div className='starreview'>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>Step into full stack Angular</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={backendimage} alt="" style={{ width: '250px' }} />
                <div className='starreview'>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>Step into full stack java backend</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={Reactimage} alt="" style={{ width: '250px' }} />
                <div className='starreview'>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>Step into full stack react</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/SecondPage">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={Angularimage} alt="" style={{ width: '250px' }} />
                <div className='starreview'>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>Step into full stack Angular</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={backendimage} alt="" style={{ width: '250px' }} />
                <div className='starreview'>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>Step into full stack java backend</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={Reactimage} alt="" style={{ width: '250px' }} />
                <div className='starreview'>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>Step into full stack react</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/SecondPage">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={Angularimage} alt="" style={{ width: '250px' }} />
                <div className='starreview'>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>Step into full stack Angular</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={backendimage} alt="" style={{ width: '250px' }} />
                <div className='starreview'>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>Step into full stack java backend</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div class="job_orientation">
        <h1>Web Technologies</h1>
        <div class="Job_orientation_container">
          <Swiper
            slidesPerView={3}
            centeredSlides={false}
            slidesPerGroupSkip={3}
            grabCursor={true}
            scrollbar={false}
            navigation={false}
            pagination={{
              clickable: true,
            }}
            display={false}
            breakpoints={{
              319: {
                slidesPerView: 1,
              },
              479: {
                slidesPerView: 2,
              },
              767: {
                slidesPerView: 3,
              }
            }}
            modules={[Keyboard, Scrollbar, Navigation, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div class="swippercard ">
                <img src={reacttranning} alt="" style={{ width: '250px' }} />
                <div>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>REACTjS TRAINING,</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={angulartranning} alt="" style={{ width: '250px' }} />
                <div>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>ANGULAR TRAINING</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={mean} alt="" style={{ width: '250px' }} />
                <div>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>MEAN STACK</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={mern} alt="" style={{ width: '250px' }} />
                <div>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>MEAN STACK</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={reacttranning} alt="" style={{ width: '250px' }} />
                <div>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>REACTjS TRAINING,</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={angulartranning} alt="" style={{ width: '250px' }} />
                <div>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>ANGULAR TRAINING</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={mean} alt="" style={{ width: '250px' }} />
                <div>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>MEAN STACK</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swippercard test">
              <h2 class="overlay_text"><img src={lock}/>Coming Soon</h2>
                <img src={mern} alt="" style={{ width: '250px' }} />
                <div>
                  <p>Review</p>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => handleStarClick(star)}
                      style={{
                        cursor: 'pointer',
                        color: star <= rating ? 'gold' : 'gray',
                      }}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <hr />
                <h2>MEAN STACK</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem impedit quasi alias eaque perferendis
                  amet eligendi numquam ad mollitia ducimus, cum dolorum odio consectetur. Qui, placeat? Molestiae aut
                  rem
                  porro.

                </p>
                <div class="card_btns">
                  <button type="submit" class="learn_more"><Link to="/second">View More</Link></button>
                  <button type="submit" class="apply">Apply</button>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div class="our_legacy">
        <div class="legacy_main">
          <div class="carousel-container">
            <h1>"Our Legacy is Written in Your Success Stories"</h1>
            <Swiper
              slidesPerView={2}
              centeredSlides={false}
              slidesPerGroupSkip={2}
              grabCursor={true}
              keyboard={{ enabled: true, }}
              scrollbar={false}
              navigation={false}
              pagination={{
                clickable: true,
              }}
              
              breakpoints={{
                319: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },

              }}
              modules={[Keyboard, Scrollbar, Navigation, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div class="legacy_container">
                  <h1>Inphynous Technical Academy</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem necessitatibus atque
                    odio ratione vel reiciendis?
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="legacy_container">
                  <h1>Inphynous Technical Academy</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem necessitatibus atque
                    odio ratione vel reiciendis?
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="legacy_container">
                  <h1>Inphynous Technical Academy</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem necessitatibus atque
                    odio ratione vel reiciendis?
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="legacy_container">
                  <h1>Inphynous Technical Academy</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem necessitatibus atque
                    odio ratione vel reiciendis?
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div class="our_legacy_image">
            <img src={image2} alt="" style={{ width: '25em' }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Firstpager

