import React, { useState } from 'react';
import WorkingTable from './Assets/workingtable.png'

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    });
  };

  return (
    <>
      <div className='Contactus_Container'>
        <h1>Contact Us</h1>
      </div>
      <div className='from_container'>
      <div className='illustration'>
          <img src={WorkingTable} style={{
            height:'500px'
          }}/>
        </div>
        <form onSubmit={handleSubmit}>
        <div className='from_content'>
          <h2>We Are Head-Quatered in</h2>
          <p>
            Office No-110, Elite Square Building, Near MIT College,<br /> Bid-Bypass
            Chh.Samhaji-Nagar,<br/> +91 240-297-5010
          </p>
          <div className="map" >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.7010655398453!2d75.31351275089517!3d19.85259763401102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdb99fe1863fbc5%3A0x6c3c4b18249a6346!2sVASTU%20ELITE%20SQUARE!5e0!3m2!1sen!2sin!4v1707474783490!5m2!1sen!2sin" width={500} height={350} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Google Map' />
          </div>
        </div>
          <input
            type="text"
            name="name"
            value={formData.name}
            placeholder='Name'
            onChange={handleInputChange}
            required
          />
          <br />

          <input
            type="email"
            name="email"
            value={formData.email}
            placeholder='Email'
            onChange={handleInputChange}
            required
          />
          <br />

          <input
            type="tel"
            name="phone"
            value={formData.phone}
            placeholder='Phone Number'
            onChange={handleInputChange}
          />
          <br />

          <input
            type="text"
            name="subject"
            value={formData.subject}
            placeholder='Subject'
            onChange={handleInputChange}
          />
          <br />

          <textarea
            name="message"
            value={formData.message}
            placeholder='Your Message'
            onChange={handleInputChange}
            required
          />
          <br />
          <button type="submit">Submit</button>

        </form>
       
      </div>
    </>
  );
};

export default Contactus;
