import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Style.css/Demo.css'
import logo from './Assets/Final_Inphynous_logo.png'
import hamburger from './Assets/icons8-menu-100.png'
import closebutton from './Assets/closeButton.png'

const MenuItem = ({ item }) => {
    const [isActive, setIsActive] = useState(false);

    const handleMouseEnter = () => {
        setIsActive(true);
    };

    const handleMouseLeave = () => {
        setIsActive(false);
    };
    return (
        <li
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {item.subMenu && item.subMenu.length > 0 ? (
                <span>{item.label}</span>
            ) : (
                <Link to={item.linkTo}>{item.label}</Link>
            )}
            {item.subMenu && item.subMenu.length > 0 && isActive && (
                <ul>
                    {item.subMenu.map((subMenuItem) => (
                        <MenuItem key={subMenuItem.id} item={subMenuItem} />
                    ))}
                </ul>
            )}
        </li>
    );
};

const Navbar = () => {
    const menuItems = [
        {
            id: 1,
            label: 'Home',
            linkTo: '/',
        },
        {
            id: 2,
            label: 'All Courses',
            linkTo: '/Allcourses',
            // subMenu: [
            //     {
            //         id: 21,
            //         label: 'Technologies',
            //         subMenu: [
            //             { id: 213, label: 'React Full Satck', linkTo: '/SecondPage', },
            //             { id: 214, label: 'Angular Full Satck' },
            //             { id: 214, label: 'Java Backend' },
            //             { id: 214, label: 'AWS' },
            //             { id: 214, label: 'C & .NET' },
            //         ],
            //     },
            //     {
            //         id: 22, label: 'Markets',
            //         subMenu: [
            //             { id: 213, label: 'Stock markets Basics' },
            //             { id: 214, label: 'BackTechnical Analysisend' },
            //             { id: 214, label: 'Fundamental Analysis' },
            //             { id: 214, label: 'Forex Basics' },
            //             { id: 214, label: 'Forex Advanced' },
            //         ],
            //     },
            // ],
        },
        {
            id: 3,
            label: 'About Us',
            subMenu: [
                { id: 31, label: 'Vision And Mission', linkTo: '/VisionandMission', },
                { id: 32, label: 'Management Team', linkTo: '/Management', },
                { id: 32, label: 'ITA', linkTo: '/Aboutus', },
            ],
        },
        {
            id: 4,
            label: 'Contact Us',
            linkTo: '/Contactus',
        },
    ];

    const [isNavExpanded, setIsNavExpanded] = useState(false)


    return (
        <>
            <nav className="navigation" id="navigation">
                <div className="logo brand-name"> <img src={logo} alt='logo' style={{ width: '150px' }} /></div>
                <div className={
                    isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                }>
                    <ul onClick={() => {
                        setIsNavExpanded(false);
                    }}>
                        {menuItems.map((menuItem) => (
                            <MenuItem key={menuItem.id} item={menuItem} />
                        ))}
                        <div className='closeNav' onClick={() => {
                            setIsNavExpanded(false);
                        }}>
                            <img src={closebutton} style={{ width: '30px' }}></img>
                        </div>
                    </ul>
                </div>
                <div className="hamburger" onClick={() => {
                    setIsNavExpanded(!isNavExpanded)
                }}><img src={hamburger} style={{ width: '30px' }}></img></div>
            </nav >
        </>
    );
};

export default Navbar;