import React, { useState } from 'react';

function AllCourses() {
  const initialData = [
  { technology: 'React Full Satck', description: 'React Building Dynamics User Interface  With  The  Popular Javascript library', duration: '6 months', enrollNow: '/Secondpage', button: 'Pay'  },
  { technology: 'JAVA Backend', description: 'JavaScript runtime built on Chrome\'s V8 JavaScript engine', duration: '3 months', enrollNow: 'Link2', button: 'Pay'  },
  { technology: 'Angular Full Stack', description: 'Web application framework for Node.js', duration: '6 months', enrollNow: 'Link3', button: 'Pay'  },
  { technology: 'AWS', description: 'Web application framework for Node.js', duration: '2 months', enrollNow: 'Link3', button: 'Pay'  },
  { technology: 'C & .NET', description: 'Web application framework for Node.js', duration: '6 months', enrollNow: 'Link3', button: 'Pay'  },
  { technology: 'React.JS', description: 'Web application framework for Node.js', duration: '3 months', enrollNow: 'Link3', button: 'Pay'  },
  { technology: 'Angular.Js', description: 'Web application framework for Node.js', duration: '3 months', enrollNow: 'Link3', button: 'Pay' },
  ];

  const [tableData, setTableData] = useState(initialData);

  return (
    <div className='Courses_table'>
      <table>
        <thead>
          <tr style={{ borderBottom: '1px solid #ddd' }}>
            <th style={{
              padding: '8px',
              textAlign: 'justify',
              color: 'white'
            }}>Technology</th>
            <th style={{
              padding: '8px',
              textAlign: 'justify',
              color: 'white'
            }}>Description</th>
            <th style={{
              padding: '8px',
              textAlign: 'justify',
              color: 'white'
            }}>Duration</th>
            <th style={{
              padding: '8px',
              textAlign: 'justify',
              color: 'white'
            }}>Enroll Now</th>
            <th style={{
              padding: '8px',
              textAlign: 'justify',
              color: 'white'
            }}>Pay Now</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={row.enrollNow} style={{ opacity: index === 0 ? 1 : 0.5 }}>
              <td className='coursesName' style={{ padding: '10px ', textAlign: 'left' }}>{row.technology}</td>
              <td style={{ padding: '10px', textAlign: 'left' }}>{row.description}</td>
              <td style={{ padding: '10px', textAlign: 'left' }}>{row.duration}</td>
              <td style={{ padding: '10px', textAlign: 'left' }}>
                <a href={row.enrollNow} disabled={index !== 0} >Enroll in {row.technology} </a>
              </td>
              <td style={{ padding: '10px', textAlign: 'left' }}>
                <a href={row.button} disabled={index !== 0} >
                  <button disabled={index !== 0}>{row.button}</button>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='next_page_link' style={{ margin: '5em', fontWeight: 'bold', textAlign: 'center', display:'none' }} >
        <a href='/MarketingCourses'><h1> Also Want to Explore the Marketing Courses</h1></a>
      </div>
    </div>
  );
}

export default AllCourses;
