import React from 'react'
import Footerlogo from './Assets/Final_Inphynous_logo.png';
import facebook from './Assets/Facebook.png'
import twitter from './Assets/Twitter.png'
import linkedin from './Assets/Linkedin.png'
import email from './Assets/Mail.png'
import phone from './Assets/Phone.png'

function Footer() {
    return (
        <>
            <footer>
                <div className='footerContent'>
                    <div class="logoandContact">
                        <a href='/'><img src={Footerlogo} alt="" style={{ width: '200px' }} /></a>
                        <div className="contact">
                            <img src={facebook} style={{ width: '25px' }} />
                            <img src={twitter} style={{ width: '25px' }} />
                            <img src={linkedin} style={{ width: '30px' }} />
                            <img src={email} style={{ width: '25px' }} />
                            <img src={phone} style={{ width: '25px' }} />
                        </div>
                    </div>
                    <div class="information">
                        <h1><u>Information</u></h1>
                        <ul>
                            <li><a href='/Contactus'>Contact Us</a></li>
                            <li>Terms and Condition</li>
                            <li>Privacy policy</li>
                            <li><a href='/Allcourses'>All Cources</a></li>
                        </ul>
                    </div>
                    <div class="popular_Courses">
                        <h1><u>Popular Courses</u></h1>
                        <ul>
                            <li><a href='/SecondPage'>React Developer</a></li>
                            <li>AWS</li>
                            <li>Angular Developer</li>
                            <li>Backend Developer</li>
                        </ul>
                    </div>
                </div>
                <hr/>
                <div className='copyright'>
                    <div className='toparrow'>
                        <a href='#navigation'>Take Me to Top</a>
                    </div>
                    <p>
                        copyright @2024 Inphynous Tercnical Solution
                    </p>
                </div>
            </footer>
        </>
    )
}

export default Footer