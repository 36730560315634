import React, { useState } from 'react';

function MarketingCourses() {
    const initialData = [
        { technology: 'Stock Market Basics', description: 'Understanding Markets Terms, Trading Stratergies & Risk Management', duration: '6 months', enrollNow: '/Secondpage', button: 'pay' },
        { technology: 'Technical Analysis', description: 'Analysis Price Charts, Patterns & Indicators for Informed Decision Making ', duration: '3 months', enrollNow: 'Link2', button: 'pay' },
        { technology: 'Fundamental Analysis', description: 'Evaluating Financial Statements Company Performance & Markets Trends', duration: '6 months', enrollNow: 'Link3', button: 'pay' },
        { technology: 'Forex Basics', description: 'Eight Majors of Forex, Predicting Price Movements, Forex Yield and Return, Using Leverage in Forex Trades, How to Win with Carry Trades,  The Bottom Line', duration: '2 months', enrollNow: 'Link3', button: 'pay' },
        { technology: 'Forex Advanced', description: 'To understand what moves the currency market., To analyse market moving economic data., To understand currency correlations and how to use them, Learn about forex market in-depth, Understand global transaction, Identify stock patterns', duration: '6 months', enrollNow: 'Link3', button: 'pay' },
    ];
    // const [tableData, setTableData] = useState(initialData);
    const [tableData] = useState(initialData);


    return (
        <div className='Courses_table' >
            <h1 style={{
                backgroundColor: 'white', color: 'black'
            }}
            > Here Are Some Of Our ' Marketing Courses '</h1>
            <table>
                <thead>
                    <tr style={{ borderBottom: '1px solid #ddd' }}>
                        <th style={{
                            padding: '8px',
                            textAlign: 'justify',
                            color: 'white'
                        }}>Technology</th>
                        <th style={{
                            padding: '8px',
                            textAlign: 'justify',
                            color: 'white'
                        }}>Description</th>
                        <th style={{
                            padding: '8px',
                            textAlign: 'justify',
                            color: 'white'
                        }}>Duration</th>
                        <th style={{
                            padding: '8px',
                            textAlign: 'justify',
                            color: 'white'
                        }}>Enroll Now</th>
                        <th style={{
                            padding: '8px',
                            textAlign: 'justify',
                            color: 'white'
                        }}>Pay Now</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row) => (
                        <tr key={row.enrollNow}>
                            <td className='coursesName'>{row.technology}</td>
                            <td>{row.description}</td>
                            <td style={{ textAlign: 'left' }}>{row.duration}</td>
                            <td><a href={row.enrollNow} target="_blank" rel="noopener noreferrer" >Enroll in {row.technology} </a></td>
                            <td style={{ padding: '10px', textAlign: 'left' }}><a href={row.button} > <button>{row.button}</button> </a></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MarketingCourses;
