import React from 'react'

function VisionandMission() {

    return (
        <div className='VisionandMission' >
            <h1>Vision & Mission</h1>
            <h3 style={{
                color: 'grey'
            }}>Our Vision</h3>
            <p style={{
                color: 'black', fontWeight: 'bolder'
            }}>
                To be a leading force in tech education, ensuring every ITA entrant leaves not just informed but armed with practical skills for a tech-driven future.
            </p>
            <h3 style={{
                color: 'grey'
            }}> Our Mission </h3>
            <p style={{
                color: 'black', fontWeight: 'bolder'
            }}>Empower all who walk through our doors with hands-on knowledge, practice, and a deep understanding of IT essentials. Through dynamic learning and mentorship, we aim to cultivate tech-ready professionals who thrive in the ever-evolving digital landscape.
            </p>
        </div>
    )
}

export default VisionandMission