import React from 'react'

function Aboutus() {
  return (
    <div className='aboutus'>
      <div className='whoandwhy' style={{
        marginTop: '6.1em', marginBottom: '5em',
      }}>
        <h1>About Us</h1>
        <h2> Who We Are:</h2>
        <p style={{
          color: 'black', fontWeight: 'bolder'
        }}>
          Inphynous Technical Academy is not just an educational institution; we are architects of transformative learning experiences. Comprising a seasoned management team with over 50 years of collective industry experience, we are committed to shaping the future of IT professionals. Our trainers, boasting 5 years of expertise, embody a passion for excellence and a vision for empowering students with practical skills.
        </p>
        <h2> Why Choose Us: </h2>

        <p style={{
          color: 'black', fontWeight: 'bolder'
        }}>
          Industry-Driven Curriculum: Our courses are meticulously crafted to align with industry needs, ensuring graduates are equipped with relevant and up-to-date knowledge.
          Project-Based Learning: We believe in learning by doing. Our hands-on, project-based approach ensures students gain practical experience and problem-solving skills.
          Community Support: Join a vibrant community of learners and industry professionals. Collaborate, network, and thrive in an environment where collective success is celebrated.
          Experienced Trainers: Learn from instructors with a rich background in the IT industry. Our trainers are not just educators; they are mentors dedicated to your success.
          Visionary Leadership: Our management team's visionary approach guides the academy toward innovation, ensuring you receive an education that prepares you for the future of technology.
        </p>
        <h2>We Are Head-Quatered in</h2>
        <p style={{
          color: 'black', fontWeight: 'bolder'
        }}>
          Office No-110,<br /> Elite Square Building,<br /> Near MIT College,<br /> Bid-Bypass <br />
          Chh.Samhaji-Nagar <br /> +91 240-297-5010
        </p>
      </div>


    </div>
  )
}

export default Aboutus