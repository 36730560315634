import React, { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ThreePIcon from '@mui/icons-material/ThreeP';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import joincourse from "./Assets/joincourse.png"
import img1 from "./Assets/img1.webp";
import img2 from "./Assets/img2.png";
import img3 from "./Assets/img3.png";
import img5 from "./Assets/img5.png";
import img6 from "./Assets/img6.png";
import img7 from "./Assets/img7.png";
import img8 from "./Assets/img8.png";
import img4 from "./Assets/img4.png";
import Pomplate from "./Assets/Pomplate.jpg";
import Graph01 from "./Assets/Graph01.jpg"
import Graph02 from "./Assets/Graph02.jpg";
import './Style.css/SecondPage.css'


const SecondPage = () => {
    return (

        <div className='ViewMore'>
            <div className='Conteiner-1'>
                <h1>
                    REACT FULL STACK DEVELOPMENT
                </h1>
                <p>"Master the art of React Full Stack Development and bring dynamic,
                    responsive web  <br />applications to life. Dive into the world of front-end excellence with React,  <br />seamlessly integrated with powerful back-end technologies. Elevate your skill set <br /> and become a proficient developer capable of crafting robust,  <br />end-to-end solutions for the modern web</p>
                <div className='start-div'>
                    <div className='dateandregister'>
                        <h2>Start at 19th Feb, 2024</h2>
                        <p>136 already registered</p>
                    </div>
                    <div className='result'>
                        <p><StarIcon className='star' /> <StarIcon className='star' /><StarIcon className='star' /><StarIcon className='star' /><StarIcon className='star' /></p>
                        <p >more then 99% of Students rate this Course <br /> content and results as Super</p>
                    </div>
                </div>
                <button>REGISTER</button>
                <div className='action-button'><a>SKILLS YOU GET</a> <a>COURSE PROGRAM</a></div>
            </div>
            <div className='Conteiner-2'>
                <h1>KEY FEATURES</h1>
                <div className='KeyFeaturesMainContainer'>
                    <div className='FeaturesContainer'>
                        <div className='KeyFeatures01'>
                            <div><CalendarMonthIcon className='KeyFeatures-icon' />Course duration: 6 months</div>
                            <div><ThreePIcon className='KeyFeatures-icon' />Real Time Project:2</div>
                            <div><EditCalendarIcon className='KeyFeatures-icon' /> Project Based Learing</div>
                        </div>
                        <div className='KeyFeatures02'>
                            <div><HandshakeIcon className='KeyFeatures-icon' />EMI Opetion Avilbale</div>
                            <div><ChromeReaderModeIcon className='KeyFeatures-icon' />Real Time Project:2</div>
                            <div><AccessTimeIcon className='KeyFeatures-icon' /> Project Based Learing</div>
                        </div>
                    </div>

                    <img src={Pomplate} width={500} />
                </div>
            </div>
            <div className='Conteiner-3'>
                <div className='Skill'>
                    <h1>Skills required</h1>
                    <p><CheckCircleIcon style={{ color: "green", padding: " 0 5px" }} />No prerequisites for react full stack.</p>
                    <p><CheckCircleIcon style={{ color: "green", padding: " 0 5px" }} />Basic knowledge of HTML and css is advantages</p>
                </div>
                <div className='Conteiner-div-2'>
                    <div className='placement_support'>
                        <div className='Sub-div'>
                            <h1>100%</h1>
                            <p>Placement Assurance</p>
                        </div>
                        <div className='Sub-div'>
                            <h1>24x7</h1>
                            <p>24x7 support & Access</p>
                        </div>
                    </div>

                    <div className='hourse_activity'>
                        <div className='Sub-div'>
                            <h1>200</h1>
                            <p>Hours Course</p>
                        </div>
                        <div className='Sub-div'>
                            <h1>100%</h1>
                            <p>Extra Activities, Sessions</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Conteiner-4'>
                <div>
                    <h1>who can join this course</h1>
                    <p><CheckIcon className='CheckIcon' />Recent Graduates:</p>
                    <p><CheckIcon className='CheckIcon' />Post-Graduates:</p>
                    <p><CheckIcon className='CheckIcon' />Tech Enthusiasts:</p>
                    <p><CheckIcon className='CheckIcon' />Career Changers:</p>
                    <p><CheckIcon className='CheckIcon' />Entrepreneurs and Start-up Enthusiasts:</p>
                    <p><CheckIcon className='CheckIcon' /> Freelancers and Gig Workers:</p>
                    <p><CheckIcon className='CheckIcon' /> Professionals Seeking Skill Enhancement:</p>
                    <p><CheckIcon className='CheckIcon' /> Individuals Returning to Workforce:</p>
                    <p><CheckIcon className='CheckIcon' /> Project Managers and Team Leads:</p>
                    <p><CheckIcon className='CheckIcon' /> Life-Long Learners:</p>
                    <p><CheckIcon className='CheckIcon' /> salary graph of full stack developer:</p>
                </div>
                <div className='courseImage'>
                    <img src={joincourse} alt='not ' width={415} />
                </div>
            </div>
            <div className='Conteiner-5'>
                <div className='Graphs_Container'>
                    <img src={Graph01} alt=''></img>
                    <img src={Graph02} alt=''></img>
                </div>
            </div>
            <div className='Conteiner-6'>
                <h1>Skills you will get</h1>
                <div className='Courses_cards'>
                    <div className='Conteiner-div01'>
                        <div class="main">
                            <div class="card">
                                <div class="card_content">
                                    <label class="switch_738">
                                        <img src={img1} width={100} />
                                    </label>
                                </div>
                                <div class="card_back"></div>
                            </div>
                            <div class="data">

                                <div class="text">
                                    <div class="text_m">
                                        <h2>HTML5</h2>
                                    </div>

                                </div>
                            </div>
                            <div class="btns">
                                <div class="likes">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi voluptatem omnis cupiditate voluptates
                                    laudantium. Illum, enim ipsa quibusdam minus deleniti possimus fuga et suscipit omnis corporis voluptatum eum
                                    magnam vitae reiciendis eius. Autem, consequatur voluptate culpa asperiores sequi recusandae mollitia ad est
                                    error corrupti. Quibusdam?
                                </div>
                            </div>
                        </div>
                        <div class="main">
                            <div class="card">

                                <div class="card_content">
                                    <label class="switch_738">
                                        <img src={img2} width={100} />
                                    </label>
                                </div>
                                <div class="card_back"></div>
                            </div>
                            <div class="data">

                                <div class="text">
                                    <div class="text_m">
                                        <h2>CSS3</h2>
                                    </div>

                                </div>
                            </div>
                            <div class="btns">
                                <div class="likes">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi voluptatem omnis cupiditate voluptates
                                    laudantium. Illum, enim ipsa quibusdam minus deleniti possimus fuga et suscipit omnis corporis voluptatum eum
                                    magnam vitae reiciendis eius. Autem, consequatur voluptate culpa asperiores sequi recusandae mollitia ad est
                                    error corrupti. Quibusdam?
                                </div>
                            </div>
                        </div>
                        <div class="main">
                            <div class="card">

                                <div class="card_content">
                                    <label class="switch_738">
                                        <img src={img3} width={100} />
                                    </label>
                                </div>
                                <div class="card_back"></div>
                            </div>
                            <div class="data">

                                <div class="text">
                                    <div class="text_m">
                                        <h2>JS</h2>
                                    </div>

                                </div>
                            </div>
                            <div class="btns">
                                <div class="likes">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi voluptatem omnis cupiditate voluptates
                                    laudantium. Illum, enim ipsa quibusdam minus deleniti possimus fuga et suscipit omnis corporis voluptatum eum
                                    magnam vitae reiciendis eius. Autem, consequatur voluptate culpa asperiores sequi recusandae mollitia ad est
                                    error corrupti. Quibusdam?
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='Conteiner-div02'>
                        <div class="main">
                            <div class="card">

                                <div class="card_content">
                                    <label class="switch_738">
                                        <img src={img4} width={100} />
                                    </label>
                                </div>
                                <div class="card_back"></div>
                            </div>
                            <div class="data">

                                <div class="text">
                                    <div class="text_m">
                                        <h2>ADAPTIVE-LAYOUT</h2>
                                    </div>

                                </div>
                            </div>
                            <div class="btns">
                                <div class="likes">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi voluptatem omnis cupiditate voluptates
                                    laudantium. Illum, enim ipsa quibusdam minus deleniti possimus fuga et suscipit omnis corporis voluptatum eum
                                    magnam vitae reiciendis eius. Autem, consequatur voluptate culpa asperiores sequi recusandae mollitia ad est
                                    error corrupti. Quibusdam?
                                </div>
                            </div>
                        </div>
                        <div class="main">
                            <div class="card">

                                <div class="card_content">
                                    <label class="switch_738">
                                        <img src={img5} width={100} />
                                    </label>
                                </div>
                                <div class="card_back"></div>
                            </div>
                            <div class="data">

                                <div class="text">
                                    <div class="text_m">
                                        <h2>REDUX</h2>
                                    </div>

                                </div>
                            </div>
                            <div class="btns">
                                <div class="likes">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi voluptatem omnis cupiditate voluptates
                                    laudantium. Illum, enim ipsa quibusdam minus deleniti possimus fuga et suscipit omnis corporis voluptatum eum
                                    magnam vitae reiciendis eius. Autem, consequatur voluptate culpa asperiores sequi recusandae mollitia ad est
                                    error corrupti. Quibusdam?
                                </div>
                            </div>
                        </div>
                        <div class="main">
                            <div class="card">

                                <div class="card_content">
                                    <label class="switch_738">
                                        <img src={img6} width={100} />
                                    </label>
                                </div>
                                <div class="card_back"></div>
                            </div>
                            <div class="data">

                                <div class="text">
                                    <div class="text_m">
                                        <h2>JQUERY</h2>
                                    </div>

                                </div>
                            </div>
                            <div class="btns">
                                <div class="likes">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi voluptatem omnis cupiditate voluptates
                                    laudantium. Illum, enim ipsa quibusdam minus deleniti possimus fuga et suscipit omnis corporis voluptatum eum
                                    magnam vitae reiciendis eius. Autem, consequatur voluptate culpa asperiores sequi recusandae mollitia ad est
                                    error corrupti. Quibusdam?
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='Conteiner-div03'>
                        <div class="main">
                            <div class="card">
                                <div class="card_content">
                                    <label class="switch_738">
                                        <img src={img7} width={100} />
                                    </label>
                                </div>
                                <div class="card_back"></div>
                            </div>
                            <div class="data">

                                <div class="text">
                                    <div class="text_m">
                                        <h2>NODE</h2>
                                    </div>

                                </div>
                            </div>
                            <div class="btns">
                                <div class="likes">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi voluptatem omnis cupiditate voluptates
                                    laudantium. Illum, enim ipsa quibusdam minus deleniti possimus fuga et suscipit omnis corporis voluptatum eum
                                    magnam vitae reiciendis eius. Autem, consequatur voluptate culpa asperiores sequi recusandae mollitia ad est
                                    error corrupti. Quibusdam?
                                </div>
                            </div>
                        </div>
                        <div class="main">
                            <div class="card">

                                <div class="card_content">
                                    <label class="switch_738">
                                        <img src={img8} width={100} />
                                    </label>
                                </div>
                                <div class="card_back"></div>
                            </div>
                            <div class="data">

                                <div class="text">
                                    <div class="text_m">
                                        <h2>WEB DEVELOPMENT</h2>
                                    </div>

                                </div>
                            </div>
                            <div class="btns">
                                <div class="likes">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi voluptatem omnis cupiditate voluptates
                                    laudantium. Illum, enim ipsa quibusdam minus deleniti possimus fuga et suscipit omnis corporis voluptatum eum
                                    magnam vitae reiciendis eius. Autem, consequatur voluptate culpa asperiores sequi recusandae mollitia ad est
                                    error corrupti. Quibusdam?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='Collapsible' >
                <h1>An Overview</h1>
                <details>
                    <summary>HTMl</summary>
                    <h2>Introduction to HTML</h2>
                    <ul>
                        <li>Understanding the role of HTML in web development</li>
                        <li>HTML basic structure and elements</li>
                        <li>Creating a simple HTML document</li>
                    </ul>
                    <h2>HTML Document Structure</h2>
                    <ul>
                        <li>Document type declaration</li>
                        <li>HTML head and body elements</li>
                        <li>Meta tags and their significance</li>
                    </ul>
                    <h2>HTML Text Formatting</h2>
                    <ul>
                        <li>Paragraphs and line breaks</li>
                        <li>Headings and subheadings</li>
                        <li>Text styles (bold, italic, underline)</li>
                        <li>Lists (ordered and unordered)</li>
                    </ul>
                    <h2>Hyperlinks</h2>
                    <ul>
                        <li>Creating internal and external links</li>
                        <li>Linking to email addresses and anchors</li>
                        <li>Using images as links</li>
                    </ul>
                    <h2>HTML Images</h2>
                    <ul>
                        <li>Adding images to web pages</li>
                        <li>Image attributes and best practices</li>
                    </ul>
                    <h2>HTML Tables</h2>
                    <ul>
                        <li>Creating and formatting tables</li>
                        <li>Table headers and data cells</li>
                        <li>Merging cells and table accessibility</li>
                    </ul>
                    <h2>HTML Forms</h2>
                    <ul>
                        <li>Building web forms for user input</li>
                        <li>Form elements (text fields, radio buttons, checkboxes, etc.)</li>
                        <li>Form submission and handling</li>
                    </ul>
                </details>
                <details>
                    <summary>CSS</summary>
                    <h2>Introduction to CSS</h2>
                    <ul>
                        <li>Understanding the role of CSS in web development</li>
                        <li>Inline, internal, and external CSS</li>
                    </ul>
                    <h2>CSS Selectors and Properties</h2>
                    <ul>
                        <li>Working with different types of CSS selectors</li>
                        <li>Applying styles using various CSS properties</li>
                    </ul>
                    <h2>CSS Box Model</h2>
                    <ul>
                        <li>Understanding the concept of the box model</li>
                        <li>Margin, padding, border, and content</li>
                    </ul>
                    <h2>CSS Layout</h2>
                    <ul>
                        <li>Positioning elements (static, relative, absolute, fixed)</li>
                        <li>Floating elements and clearing floats</li>
                        <li>Creating responsive layouts with media queries</li>
                    </ul>
                    <h2>CSS Typography</h2>
                    <ul>
                        <li>Styling text (font-family, font-size, color, etc.)</li>
                        <li>Text alignment and decoration</li>
                    </ul>
                    <h2>CSS Colors and Backgrounds</h2>
                    <ul>
                        <li>Using different color formats</li>
                        <li>Applying background colors and images</li>
                    </ul>
                    <h2>CSS Transitions and Animations</h2>
                    <ul>
                        <li>Creating smooth transitions and animations</li>
                        <li>Transforming and animating elements</li>
                    </ul>
                    <h2>CSS Flexbox and Grid</h2>
                    <ul>
                        <li>Understanding and using flexbox for layout</li>
                        <li>Creating grid-based layouts with CSS GridTransforming and animating elements</li>
                    </ul>
                </details>
                <details>
                    <summary>JavaScript</summary>
                    <ul>
                        <li>Basics of JavaScript.</li>
                        <li>JavaScript in depth.</li>
                        <li>Creating classes</li>
                        <li>Creating private/public/global fields.</li>
                        <li>Creating private/public/global functions</li>
                        <li>Dynamic rendering with JavaScript</li>
                        <li>Events</li>
                    </ul>
                </details>
                <details>
                    <summary>REACT</summary>
                    <ul>
                        <li>Introduction to React</li>
                        <li>Original DOM vs Virtual DOM</li>
                        <li>React Components</li>
                        <li>React Components with JSX</li>
                        <li>Creating private/public/global functions</li>
                        <li>React Components with ES6</li>
                        <li>Props and state</li>
                        <li>Node</li>
                    </ul>
                    <h2>Basic Of Node installation</h2>
                    <ul>
                        <li>Introduction to Npm</li>
                        <li>Adding and removing modules</li>
                        <li>React with Node</li>
                        <li>Creating an application using Create React App.</li>
                        <li>Life Cycle</li>
                        <li>Debugging</li>
                        <li>Default values</li>
                        <li>SetState in depth</li>
                        <li>Creating Forms</li>
                        <li>Creating Table</li>
                        <li>Handling Events</li>
                        <li>Applying Filters</li>
                        <li>JSX in depth</li>
                        <li>Validations</li>
                        <li>Applying Styles</li>
                        <li>Backend calls</li>
                        <li>Stateful Components</li>
                        <li>Stateless Components</li>
                        <li>Local Storage</li>
                        <li>Routing</li>
                        <li>Basic Routing and Passing Params</li>
                        <li>Hyperlinks</li>
                        <li>Master Pages</li>
                        <li>Reconciliation</li>
                        <li>Creating Reusable Components</li>
                        <li>React.Component vs React.pure Component</li>
                        <li>Composition vs Inheritance</li>
                        <li>Code Reusability and Optimization</li>
                        <li>Fragments</li>
                        <li>Bundling</li>
                        <li>Deploying</li>
                    </ul>
                    <h2>Integration Of 3rd Party Modules</h2>
                    <ul>
                        <li>Google Maps</li>
                        <li>Bootstrap Controls</li>
                        <li>Material UI</li>
                        <li>Toast Messages for notifications</li>
                    </ul>
                </details>
                <details>
                    <summary>Others</summary>
                    <ul>
                        <li>Developer Tools</li>
                        <li>Current Applications developed in React</li>
                        <li>Future of React.</li>
                        <li>Integration with other libraries</li>
                    </ul>
                </details>
                <details>
                    <summary>C Language <span>|</span> C++ Language <span>|</span> JAVA</summary>
                    <p>Basic Of Specific Languages, OOPs, String, Exception Handling</p>
                    <p>Collection, JDBC, Hibernate, Spring Framework</p>
                </details>

            </div>
        </div>

    );
};

export default SecondPage
